import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modalOpened: Boolean,
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AlertBoxTypeTwo extends Vue {
  private modalOpened!: boolean;
  private showCloseIcon!: boolean;

  mounted() {
    if (!this.modalOpened) {
      document.body.classList.add("modal-open");
    }
  }

  beforeUnmount() {
    if (!this.modalOpened) {
      document.body.classList.remove("modal-open");
    }
  }
}
