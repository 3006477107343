import {Inject, Options, Vue, Watch} from "vue-property-decorator";
import ModalWindow from "@/shared/components/modal-window/index.vue";
import { AddressDetail, Application, PcRenewFileRequest, QualificationInstitution, StudentNewIndividualDetail, SuburbStatePostcode } from "@/shared/modules/typescript-api-client/models";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import store from "@/shared/store";
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import _, { orderBy } from "lodash";
import FileDragAndDrop from "@/shared/components/file-drag-and-drop/index.vue";
import PostcodeSearch from "@/shared/components/postcodeSearch/index.vue";
import AlertBox from "@/shared/components/alert-box/index.vue";
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import { PopoverView } from "@/shared/components/popover/popover";
import DatePicker from 'vue-datepicker-next';
import InstitutionSearch from "@/shared/components/institutionSearch/index.vue";
import { STUDENT_EMAIL_VALIDATION_SUFFIX } from '@/shared/config/config';


@Options({
  props: {
    currentDetails: Object,
    origNames: Object,
    disallowUpdate: Object,
  },
  components: {
    FileDragAndDrop,
    LoadingIndicator,
    PostcodeSearch,
    AlertBox,
    Popover,
    ConfirmDialogue,
    ModalWindow,
    DatePicker,
    InstitutionSearch
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})

export default class StrApplicantDetailsUpdateModal extends Vue {

  private currentDetails!: StudentNewIndividualDetail;
  private origNames!: any;
  private disallowUpdate!: any;
  private requiredProofOfEnrolment!: boolean;

  //email validation
  get isEmailValid(): boolean {
    const email = this.formData?.studentEmail;
    return email.length<1 || email.endsWith(STUDENT_EMAIL_VALIDATION_SUFFIX);
  }

  private studentEmailCheck(email: string | null | undefined): boolean {
    if (!email?.trim()) {
      return false; // Empty or null emails are invalid
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  }


  @Watch('formData.studentEmail')
  private onStudentEmailChange() {
    this.requiredProofOfEnrolment = !this.isEmailValid; // If email is invalid, set to true
  }

  private isClosing = false;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private titles: any[] = [];
  // private buildingTypes: any[] = [];
  // private streetTypes: any[] = [];
  // private buildingType: any = { id: 0, name: '' };
  // private streetType: any = { id: 0, description: '' };

  // private pcRenewFileTypes: any[] = [];
  private proofOfEnrollementTypes: any[] = [];
  private fileIdToDelete = 0;

  private suburbStatePostcode = {};

  private keyword = '';
  private arrowCounter = -1;
  private searchDebounced: any = null;
  private isSearching = false;
  private stopSearch = false;
  private isOpen = false;
  private institutionList: any = [];

  private firmId = 0;
  private noSearchResult = false;
  private addNewPractice = false;

  private popover: PopoverView = {
    key:'',
    content: '<p><strong>Example:</strong></p><p>.headings {<br>font-family: var(--font-headings);<br>}</p><p>.body-text {<br>font-family: var(--font-body-text);<br>}</p>',
    isShow: false,
    title: '',
  };

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private isLoadingData = true;

  private requiredFileUpload = false;

  private formData: any = {};

  private fileList: Array<File> = [];
  private uploading = false;
  private dateReceived: Date = null as any;
  private showDeleteAlert = false;

  private isSubmitting = false;

  // mandatory fields
  private formFirstName = true;
  private formSurname = true;
  private formFileUpload = true;
  private formPhoneNumber = true;
  private formEmail = true;
  private formStudentEmail = true;
  private isEmailDuplicated = false;
  private addressCheck = true;
  private isUpdating = false;
  // private newSeed = 1;

  private formBirthPlace = true;
  private formBirthDate = true;
  private formBirthCountry = true;
  private formGender = true;

  mounted(): void {
    document.body.classList.add('modal-open');
    this.preloadData();
    this.formData = JSON.parse(JSON.stringify(this.currentDetails));
    this.formData.postalAddress = this.formData.postalAddress ? this.initAddress(this.formData.postalAddress) : this.initAddress({});
    if (!this.formData.nameChangeFiles) {
      this.formData.nameChangeFiles = [];
    }
    this.formData.birthDate = this.formData.birthDate ? new Date(this.formData.birthDate) : null;

    this.suburbStatePostcode = {
      suburbId: this.formData.postalAddress?.suburbId,
      suburbName: this.formData.postalAddress?.suburbName,
      postcode: this.formData.postalAddress?.postcode,
      stateId: this.formData.postalAddress?.stateId,
      stateName: this.formData.postalAddress?.stateName,
    }

    if (!this.formData.studentProofDocumentFiles) {
      this.formData.studentProofDocumentFiles = [];
    }

    this.nameCheck();

    this.formData.qualificationInstitution = this.formData.qualificationInstitution ?? {};


    // this.searchDebounced = debounce(() => {
    //   if (this.keyword.trim() !== '') {
    //     this.getSuburbPostcodeList();
    //   }
    // }, 500);
  }

  beforeUnmount(): void {
    document.body.classList.remove('modal-open');
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    // a.buildingName = a.buildingName || '';
    // a.buildingNumber = a.buildingNumber || null;
    // a.buildingTypeId = a.buildingTypeId || 0;
    // a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    // a.deliveryTypeId = a.deliveryTypeId || 0;
    // a.deliveryTypeName = a.deliveryTypeName || null;
    // a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    // a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    // a.dxNumber = a.dxNumber || null;
    // a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    // a.level = a.level || null;
    // a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    // a.streetName = a.streetName || null;
    // a.streetNumber = a.streetNumber || null;
    // a.streetTypeId = a.streetTypeId || 0;
    // a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.lsAddressId = a.lsAddressId || 0;
    // a.firmAddMapId = a.firmAddMapId || 0;

    return a;
  }

  private async preloadData() {
    try {
      // const _memberId: any = parseInt(this.$route.query.memberId as string, 10) || '';
      this.isLoadingData = true;
      const resp: any[] = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetTitlesGet(),
        this.apiService.CodeApi?.apiV1CodeGetStudentRenewFileTypesGet(),
        // this.apiService.CodeApi?.apiV1CodeGetGendersGet(),
      ]);
      this.titles = resp[0].data.data;
      // Validate email during preload
      this.requiredProofOfEnrolment = !this.isEmailValid;
      // this.pcRenewFileTypes = resp[1].data.data;
      // this.genderList = resp[2].data.data;
      // this.genderList = orderBy(this.genderList, ['id'], 'asc');

    } catch (err) {
      this.isLoadingData = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to preload data: ' + err);
      this.ai.trackException('Error, Unable to preload data: ' + err);
    } finally {
      this.isLoadingData = false;
    }
  }

  private updateInstitution(institution: QualificationInstitution) {
    this.formData.qualificationInstitution = institution;
  }

  private isBusy(isBusy: boolean) {
    this.$emit('isBusy', isBusy);
  }

  // private async getCountyList() {
  //   const _countryList = store.getters.getCountryList;
  //   this.countryList = _countryList == null ? null : JSON.parse(_countryList);

  //   this.isLoadingData = true;
  //   this.$emit('readyState', !this.isLoadingData);

  //   if (this.countryList == null) {
  //     await this.apiService.CodeApi?.apiV1CodeGetCountriesGet()
  //       .then((res: any) => {
  //         this.countryList = res.data.data;
  //         store.dispatch('setCountryList', this.countryList);
  //       })
  //       .catch((err: any) => {
  //         // console.log(err);
  //         this.eventCtrl.emit('show-snackbar', 'error', 'Error getting country list: ' + err.response?.status);
  //         this.ai.trackException('Error getting country list: ' + err);
  //       })
  //   }
  // }

  // private setGender(opt: number) {
  //   if (opt == 2) {
  //     this.formData.gender = { id: 2, name: "F", description: "FEMALE" }
  //   } else {
  //     this.formData.gender = { id: 1, name: "M", description: "MALE" }
  //   }
  // }

  private cancel() {
    // if (this.isDirty) {
    //   const _confirm: any = this.$refs.confirmDialogue;
    //   _confirm.show({
    //     title: 'Cancel Update Current Details',
    //     message: 'By cancelling, changes to the current screen will be lost.<br> Are you sure you want to cancel?',
    //     okButton: 'Yes',
    //     cancelButton: 'No',
    //   }).then((res: any) => {
    //     if (res) {
    //       this.close();
    //     }
    //   })
    // } else {
    this.close();
    // }
  }

  private close() {
    this.isClosing = true;
    setTimeout(() => {
      this.$emit('close');
    }, 500);
  }

  private updateAddress(address: AddressDetail, valid: boolean) {
    this.addressCheck = valid;
  }
  private async deleteStudentProofFromServerWhenEmailIsValid()
  {
    for (let i = 0; i < this.formData.studentProofDocumentFiles.length; i++) {
      this.formData.studentProofDocumentFiles.splice(i, 1);
    }
  }
  private async update() {
    this.isSubmitting = true;
    this.isUpdating = true;
    this.formData.isCurrentDetailCorrect = true;
    if (this.formValidated()) {
      this.formData.postalAddress = this.$addressCleanup(this.formData.postalAddress);
      if(!this.requiredProofOfEnrolment)
      {
        await this.deleteStudentProofFromServerWhenEmailIsValid();
      }
      await this.apiService.StudentRenewApi?.apiV1StudentRenewUpdateStudentRenewIndividualDetailPost(this.formData)
        .then(() => {
          this.isUpdating = false;
          this.eventCtrl.emit('show-snackbar', 'success', 'Current details updated');
          this.$emit('reloadCurrentDetails');
          this.close();
        })
        .catch(err => {
          this.isUpdating = false;
          if (err.response.data.errorCode == 30001) {
            this.isEmailDuplicated = true;
            this.eventCtrl.emit('show-snackbar', 'error', 'Email address is already registered');
            this.ai.trackException('Error, Unable to update current details: Email address is already registered');
          } else {
            this.eventCtrl.emit('show-snackbar', 'error', 'Unable to update current details: ' + err);
            this.ai.trackException('Error, Unable to update current details: ' + err);
          }
        })
    } else {
      this.isUpdating = false;
      this.eventCtrl.emit('show-snackbar', 'error', 'Please check all required fields');
    }

  }

  private formValidated(): boolean {
    this.formFirstName = this.formData.firstName?.trim() !== '';
    this.formSurname = this.formData.surname?.trim() !== '';

    this.formFileUpload = true;
    if (this.requiredFileUpload) {
      this.formFileUpload = this.formData.nameChangeFiles.length > 0;
    }
    this.formPhoneNumber = this.formData.mobilePhone?.phoneNumber?.trim() !== '';
    this.formEmail = this.emailCheck();
    this.formStudentEmail = this.studentEmailCheck(this.formData.studentEmail?.trim());

    // Check if proof document files are required
    const isProofRequired = !this.isEmailValid && this.formData.studentProofDocumentFiles?.length === 0;

    this.formBirthDate = this.formData.birthDate;

    return this.formSurname && this.formFileUpload && this.addressCheck && this.formPhoneNumber && this.formEmail && this.formBirthDate &&
      this.formData?.qualificationInstitution?.id >=0 && this.formData.lawDegree && this.formData.studentNumber && this.formData.studentEmail && this.formStudentEmail && !isProofRequired
  }

  private fileListChanged(fileList: Array<File>, studentNewFileType: number): void {
    this.fileList = fileList;
    this.uploadAllFiles(this.fileList, studentNewFileType);
  }

  private deleteFile(id: number, studentNewFileType: number) {
    this.fileIdToDelete = id;
    const _confirm: any = this.$refs.confirmDialogue;
    _confirm.show({
      title: 'Delete File',
      message: 'Are you sure you want to delete this file?',
      okButton: 'Delete',
      cancelButton: 'Cancel',
    }).then((res: any) => {
      if (res) {
        this.deleteFileFromServer(this.fileIdToDelete, studentNewFileType);
      }
    })

  }

  // private buildingTypeChange(bt: any) {
  //   this.formData.postalAddress.buildingTypeId = bt.id;
  //   this.formData.postalAddress.buildingTypeName = bt.name;
  // }

  // private streetTypeChange(st: any) {
  //   this.formData.postalAddress.streetTypeId = st.id;
  //   this.formData.postalAddress.streetTypeName = st.name;
  // }

  private async deleteFileFromServer(id: number, studentNewFileType: number) {
    try {
      if (studentNewFileType == 1) {
        for (let i = 0; i < this.formData.nameChangeFiles.length; i++) {
          if (this.formData.nameChangeFiles[i].id === id) {
            this.formData.nameChangeFiles.splice(i, 1);
          }
        }
      } else if (studentNewFileType == 2) {
        for (let i = 0; i < this.formData.studentProofDocumentFiles.length; i++) {
          if (this.formData.studentProofDocumentFiles[i].id === id) {
            this.formData.studentProofDocumentFiles.splice(i, 1);
          }
        }
      }
      this.nameCheck();
      this.eventCtrl.emit('show-snackbar', 'success', 'File removed.');

    } catch (err) {
      this.ai.trackException('Error on delete file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on delete file: ' + err);
    }
  }

  private async uploadAllFiles(fileList: Array<File>, studentNewFileType: number): Promise<void> {
    const allRequests: Array<Promise<boolean>> = [];
    this.uploading = true;
    try {
      if (fileList.length > 0) {
        fileList.forEach((x) => allRequests.push(this.uploadFile(x, studentNewFileType)));
        await Promise.all(allRequests);
        this.eventCtrl.emit('show-snackbar', 'success', 'File(s) uploaded');
      }
    } catch (err: any) {
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
    } finally {
      this.uploading = false;
    }
  }

  private async uploadFile(file: File, studentNewFileType: number): Promise<boolean> {
    try {
      const fileStr = await this.fileToString(file);
      const body = {
        id: 0,
        fileName: file.name,
        fileExtension: file.name,
        fileDataUrl: fileStr,
        applicationId: this.onlineApplication.id,
        studentRenewFileTypeId: studentNewFileType,
      };

      const res: any = await this.apiService.FileApi?.apiV1FileSaveStudentRenewFilePost(body);

      if (studentNewFileType == 1) {
        this.formData.nameChangeFiles.push({
          id: res.data.data.data,
          fileName: file.name,
          fileExtension: file.name,
          // fileDataUrl: fileStr,
          applicationId: this.onlineApplication.id,
          studentRenewFileTypeId: studentNewFileType,
        });

      } else if (studentNewFileType == 2)  {
        this.formData.studentProofDocumentFiles.push({
          id: res.data.data.data,
          fileName: file.name,
          fileExtension: file.name,
          // fileDataUrl: fileStr,
          applicationId: this.onlineApplication.id,
          studentRenewFileTypeId: studentNewFileType,
        })
      }

      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      this.ai.trackException('Error on upload file: ' + JSON.stringify(err));
      this.eventCtrl.emit('show-snackbar', 'error', 'Error on upload file: ' + err);
      return Promise.resolve(false);
    }
  }

  private fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        return resolve(reader.result as string);
      };

      reader.onabort = () => {
        return reject();
      }

      reader.onerror = () => {
        return reject();
      }
    });
  }

  private nameCheck() {
    const _firstNameCheck = this.formData.firstName?.trim() == this.origNames?.firstName?.trim();
    const _otherNameCheck = (this.origNames?.otherName === null && this.formData.otherName?.trim() == '') || (this.formData.otherName?.trim() == this.origNames?.otherName?.trim());
    const _surnameCheck = this.formData.surname?.trim() == this.origNames?.surname?.trim();

    if (this.origNames?.otherName === null && this.formData.otherName?.trim() == '') {
      this.formData.otherName = this.origNames?.otherName;
    }
    this.requiredFileUpload = !(_firstNameCheck && _otherNameCheck && _surnameCheck);
  }

  private emailCheck(): boolean {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(this.formData.communicationEmail?.email);
  }
  private validateStudentEmail(): void {
    this.formStudentEmail = this.studentEmailCheck(this.formData.studentEmail?.trim());
  }

  private updateSuburb(s: SuburbStatePostcode) {
    this.formData.postalAddress.suburbId = s.suburbId;
    this.formData.postalAddress.suburbName = s.suburbName;
    this.formData.postalAddress.postCodeSuburbId = s.postCodeSuburbId;
    this.formData.postalAddress.postcode = s.postcode;
    this.formData.postalAddress.stateId = s.stateId;
    this.formData.postalAddress.stateName = s.stateName;
    this.formData.postalAddress.state = s.state;
  }

  private highlightWord(word: string) {
    try {
      const regex = new RegExp('(' + this.keyword + ')', 'ig');
      return word.replace(regex, '<mark>$1</mark>');
    } catch (err) {
      return word;
    }
  }

  private onArrowDown() {
    if (this.institutionList.length > 0) {
      if (this.arrowCounter < this.institutionList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    } else {
      if (this.arrowCounter < this.institutionList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    }
  }

  private onArrowUp() {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    }
  }

  private onEnter() {
    // this.isFormDirty = false;
    this.isOpen = false;
    if (this.institutionList.length > 0) {
      this.selectInstitutionFromSearch(
        this.institutionList[this.arrowCounter === -1 ? 0 : this.arrowCounter]
      );
    }
  }

  private selectInstitutionFromSearch(p: any) {
    this.isOpen = false;
    this.stopSearch = true;
    this.keyword = p.firmName;
    setTimeout(() => {
      this.stopSearch = false;
    }, 500)
    // this.apiService.PcRenewApi?.apiV1PcRenewGetPracticeByFirmIdFirmIdGet(p.firmId)
    //   .then(async res => {
    //     this.newRecord = res.data.data;
    //     this.newRecord.pcRenewApplicationId = this.pcRenewApplicationId;
    //     this.recordPracticeAddresses = JSON.parse(JSON.stringify(this.newRecord.recordPracticeAddresses));
    //     this.recordPracticeAddresses = this.recordPracticeAddresses.map((item) => {
    //       return {
    //         id: item.id,
    //         isSelected: item.isSelected,
    //         address: item.address,
    //         inlineAddress: this.getAddressInline(item.address),
    //         businessPhone: item.businessPhone,
    //         businessFax: item.businessFax
    //       };
    //     });
    //     this.recordPracticeAddresses = orderBy(this.recordPracticeAddresses, ['inlineAddress', 'asc']);
    //     this.selectedAddressIndex = 0;
    //     this.getAddressList();
    //     this.getAddressId();
    //     await this.apiService.CodeApi?.apiV1CodeGetEmploymentStatusByFirmIdFirmIdGet(this.newRecord.firmId)
    //       .then(res => {
    //         this.employmentStatusList = res.data.data;
    //         this.employmentStatusList = orderBy(this.employmentStatusList, ['name'], 'asc');
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //   })
    //   .catch(err => {
    //     this.eventCtrl.emit('show-snackbar', 'error', 'Unable to select this practice: ' + err);
    //     this.ai.trackException('Error, Unable to select this practice: ' + err);
    //   })

    // this.$emit('updateSuburb', _suburb);
    // this.keyword = '';
  }

  get suburbError() {
    return (!this.formData?.postalAddress?.suburbId || !this.formData?.postalAddress?.suburbName || !this.formData?.postalAddress?.postcode ||
      !this.formData?.postalAddress?.stateId || !this.formData?.postalAddress?.stateName || !this.formData?.postalAddress?.state) && this.isSubmitting;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get isDirty(): boolean {
    return JSON.stringify(this.currentDetails) !== JSON.stringify(this.formData);
  }

  private disabledAfterToday(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  }

  // get hasLSID(): boolean {
  //   return this.formData.hasIndId;
  // }


  // private dateOfBirthChanged = false;
  // get disableDateOfBirth(): boolean {
  //   return this.hasLSID && this.formData.birthDate !== null;
  // }

  get disableNote(): string {
    return 'If you would like to update your current date of birth information, please get in touch with the Student Services team by emailing <a href="mailto:registry@lawsociety.com.au">registry@lawsociety.com.au</a>.'
  }

}
