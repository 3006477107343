import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "alert-box-type-two" }
const _hoisted_2 = { class: "modal-mask" }
const _hoisted_3 = {
  class: "modal-wrapper",
  ref: "modalWrapper"
}
const _hoisted_4 = { class: "modal-container" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.showCloseIcon)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "fal fa-times close-icon",
                    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('close')))
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createTextVNode(" default header ")
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "body", {}, () => [
                  _createTextVNode(" default body ")
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  _createTextVNode(" default footer "),
                  _createElementVNode("button", {
                    class: "modal-default-button",
                    onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('close')))
                  }, " OK ")
                ])
              ])
            ])
          ], 512)
        ])
      ]),
      _: 3
    })
  ]))
}