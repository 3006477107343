import { Inject, Options, Vue, Watch } from 'vue-property-decorator';
import ErrorIndicator from '@/shared/components/error-indicator/index.vue';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import LoadingSpinner from '@/shared/components/loading-spinner/index.vue';
import CurrencyFormatMixin from '@/shared/mixins/number-formatting';
import { PaymentType } from '@/shared/modules/typescript-api-client/models';
import { MARSApi } from '@/shared/modules/mars-api';
import CreditCardPayment from './credit-card-payment/index.vue';
import { EventEmitter } from 'events';
import store from '@/shared/store';
import { AppInsights } from '@/mars-app/appInsights';

@Options({
  props: {
    paymentFormData: Object,
    amountPayable: Number,
    paymentAppName: String,
  },

  components: {
    LoadingIndicator,
    ErrorIndicator,
    LoadingSpinner,
    CreditCardPayment,
  },
  mixins: [CurrencyFormatMixin],
})
export default class PaymentModal extends Vue {
  private paymentFormData!: any;
  private amountPayable!: number;
  private paymentAppName!: string;

  private paymentToken = '';
  private isCreditCardValid = false;

  @Watch('isCreditCardValid')
  private onCreditCardValidChanged(): void {
    this.$emit('isValidCard', this.isCreditCardValid);
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  private paymentMethod = null as any;
  private selectedPaymentMethod = { id: 5 } as PaymentType;

  private delay = 3000;

  private processingPayment = false;
  private errorProcessingPayment = false;
  private paymentProcessed = false;

  private cancellingPayment = false;
  private errorCancellingPayment = false;
  private cancelPaymentSucceeded = false;

  private loadingTransaction = false;
  private errorLoadingTransaction = false;
  private transactionLoaded = false;

  private ai = new AppInsights();

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  mounted(): void {
    document.body.classList.add('modal-open');
  }

  beforeUnmount() {
    document.body.classList.remove('modal-open');
  }

  private applyPaymentRequested(): void {
    this.eventCtrl.emit('process-payment-application', this.applyPayment);
  }

  private isLoadingData = false;

  private async applyPayment() {
    this.isLoadingData = true;
    this.eventCtrl.emit('cardSubmitting', true);
    if (this.paymentToken === '') return;
    this.paymentFormData.paymentDetails.singleUseTokenId = this.paymentToken;
    switch (this.paymentAppName) {
      case 'pcr-payment':
        this.applyPcrPayment();
        break;
      case 'sar-payment':
        this.applySarPayment();
        break;
      case 'sm-payment':
        this.applySmPayment();
        break;
      default:
        this.eventCtrl.emit('cardSubmitting', false);
        this.errorProcessPayment('Invalid payment application name');
        break;
    }
  }

  private async applyPcrPayment() {
    await this.apiService.PcRenewApiV2?.apiV2PcRenewSavePaymentDetailsPost(
      this.paymentFormData.paymentDetails,
    )
      .then(() => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.$emit('close');
        this.eventCtrl.emit('navigateTo', 11); // confirmation page
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.errorProcessPayment(err);
      });
  }

  private async applySarPayment() {
    await this.apiService.SpecAccRenewApiV2?.apiV2SpecAccRenewSavePaymentDetailsPost(
      this.paymentFormData.paymentDetails,
    )
      .then(() => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.$emit('close');
        this.eventCtrl.emit('navigateTo', 4); // confirmation page
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.eventCtrl.emit('cardSubmitting', false);
        this.errorProcessPayment(err);
      });
  }

  private async applySmPayment() {
    if (this.paymentAppName === 'sm-payment') {
      await this.apiService.SmApiV2?.apiV2SmSavePaymentDetailsPost(
        this.paymentFormData.paymentDetails,
      )
        .then(() => {
          this.isLoadingData = false;
          this.eventCtrl.emit('cardSubmitting', false);
          this.$emit('close');
          this.eventCtrl.emit('navigateTo', 2); // confirmation page
        })
        .catch((err) => {
          this.isLoadingData = false;
          this.eventCtrl.emit('cardSubmitting', false);
          this.errorProcessPayment(err);
        });
    }
  }

  private errorProcessPayment(err: any) {
    if (err.response?.data && err.response.data?.errorCode == 1) {
      this.eventCtrl.emit(
        'show-snackbar',
        'error',
        'An unexpected error occurred while preparing your contract. Please email dcs@lawsociety.com.au with the error code "0104". Do not resubmit payment.',
      );
      this.ai.trackException('Error on process payment: ' + JSON.stringify(err.response));
    } else {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to process payment: ' + err.message);
      this.ai.trackException('Error on process payment: ' + JSON.stringify(err));
    }
  }

  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2,
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }
}
