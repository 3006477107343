import { Inject, Options, Vue, Watch } from 'vue-property-decorator';
import Popover from '@/shared/components/popover/index.vue';
import CurrencyInput from '@/shared/components/currency-input/index.vue';
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import { AppInsights } from '@/mars-app/appInsights';
import { MARSApi } from '@/shared/modules/mars-api';
import { EventEmitter } from 'events';
import store from '@/shared/store';
import moment from 'moment';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import ErrorIndicator from '@/shared/components/error-indicator/index.vue';
import { orderBy } from 'lodash';
import AlertBoxTypeTwo from '@/shared/components/alert-box-type-two/index.vue';

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
    paymentToken: String,
  },
  emits: ['update: paymentToken'],
  components: {
    Popover,
    CurrencyInput,
    LoadingIndicator,
    ErrorIndicator,
    AlertBoxTypeTwo,
  },
  mixins: [FormatDateTimeMixin],
})
export default class PcrPaymentDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;
  private paymentToken!: string;
  private ai = new AppInsights();
  private loadingDataSet = true;

  private specAccRenewalFlag = false;
  private isSpecAccWithPracticeEmployer = false;
  private showNotificationBox = false;
  private ifAnyPaidByPractice = false;
  private isCreditCardValid = false;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('withOutMembership')
  private onWithOutMembershipChange() {
    if (this.withOutMembership !== null) {
      this.canGoNextCheck();
    }
  }

  private membershipAmount = 0;
  private defaultMembershipAmount = 440;
  private withOutMembership = null;
  private isLifeTimeMember = false;
  private disableInput = false;
  private lineItems: any[] = [];

  private ccPaymentReady = false;

  private removeMembership() {
    this.disableInput = true;
    this.withOutMembership = null;
    this.membershipAmount = 0;
    this.canGoNextCheck();
  }

  mounted(): void {
    // this.membershipAmount = this.defaultMembershipAmount;
    this.getPaymentDetails();
  }

  private async getPaymentDetails(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any =
        await this.apiService.PcRenewApiV2?.apiV2PcRenewGetPaymentDetailsApplicationIdGet(
          this.appId,
        );
      this.pcrFormData.paymentDetails = resp.data.data;

      // sort by line item order
      this.lineItems = orderBy(this.pcrFormData.paymentDetails.lineItems, ['order'], ['asc']);

      // check if any line item is paid by the practice
      this.ifAnyPaidByPractice = this.lineItems.some((x: any) => x.isPaidByPractice);

      this.showNotificationBox = this.ifAnyPaidByPractice;

      for (let i = 0; i < this.lineItems.length; i++) {
        if (this.lineItems[i].name == 'Membership') {
          this.lineItems.splice(i, 1);
        }
      }
      this.specAccRenewalFlag = resp.data.data.specAccRenewalFlag;
      this.isSpecAccWithPracticeEmployer = resp.data.data.isSpecAccWithPracticeEmployer;
      this.initSync();
      this.canGoNextCheck();
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get payment details: ' + err);
      this.ai.trackException('Error, Unable to get payment details: ' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  // get paymentTypes(): any[] {
  //   return this.pcrFormData.paymentDetails.paymentTypes;
  // }

  // get paymentItems(): any[] {
  //   return this.pcrFormData.paymentDetails.lineItems;
  // }

  private initSync(): void {
    if (
      this.pcrFormData?.paymentDetails?.noMembership ||
      this.pcrFormData?.paymentDetails?.noMembership == null
    ) {
      this.membershipAmount = this.getPayableItem('Membership').total;
      this.defaultMembershipAmount = this.getPayableItem('Membership').lsTotal;
    } else {
      this.membershipAmount = 0;
    }
    this.withOutMembership = this.pcrFormData?.paymentDetails?.noMembership ?? null;
    this.isLifeTimeMember = this.pcrFormData?.paymentDetails?.isLifeMember ?? null;
  }

  private syncData(): void {
    if (!this.loadingDataSet) {
      this.pcrFormData.paymentDetails.noMembership = this.withOutMembership;
      this.pcrFormData.paymentDetails.lineItems.forEach((x: any, i: number) => {
        if (x.name == 'Membership' && (this.withOutMembership || this.isLifeTimeMember)) {
          this.pcrFormData.paymentDetails.lineItems[i].total = 0;
          this.pcrFormData.paymentDetails.lineItems[i].gst = 0;
          this.pcrFormData.paymentDetails.lineItems[i].amountGstExcluded = 0;
        } else {
          this.pcrFormData.paymentDetails.lineItems[i].total =
            this.pcrFormData.paymentDetails.lineItems[i].lsTotal;
          this.pcrFormData.paymentDetails.lineItems[i].gst =
            this.pcrFormData.paymentDetails.lineItems[i].lsGst;
          this.pcrFormData.paymentDetails.lineItems[i].amountGstExcluded =
            this.pcrFormData.paymentDetails.lineItems[i].lsAmountGstExcluded;
        }
      });
    }
  }

  private getPayableItem(itemName: string): any {
    try {
      const _rtn = this.pcrFormData.paymentDetails.lineItems.filter(
        (x: any) => x.name.toLocaleLowerCase() == itemName.toLocaleLowerCase(),
      );

      if (_rtn.length > 0) {
        return _rtn[0];
      } else {
        throw new Error('Invalid Item');
      }
    } catch {
      return {
        name: itemName,
        amountGstExcluded: 0,
        gst: 0,
        total: 0,
        lsAmountGstExcluded: 0,
        lsFeeName: '',
        lsGst: 0,
        lsTotal: 0,
      };
    }
  }

  private inValidPayableItems(): boolean {
    return (
      this.getPayableItem('Membership').lsFeeName.length == 0 ||
      this.getPayableItem('Practising Certificate').lsFeeName.length == 0 ||
      this.getPayableItem('Fidelity Fund Contribution').lsFeeName.length == 0
    );
  }

  private updateCCStatus(status: boolean): void {
    this.ccPaymentReady = status;
    this.canGoNextCheck();
  }

  private canGoNextCheck() {
    if (this.isSpecAccWithPracticeEmployer) {
      this.eventCtrl.emit('canGoNext', this.ccPaymentReady);
    } else {
      if (this.withOutMembership == false) {
        this.disableInput = false;
        this.membershipAmount = this.defaultMembershipAmount;
      }
      this.syncData();

      const _check1 = this.membershipAmount == this.defaultMembershipAmount;
      const _check2 =
        this.membershipAmount == 0 &&
        (this.withOutMembership || this.pcrFormData?.paymentDetails?.isLifeMember);

      this.eventCtrl.emit('canGoNext', (_check1 || _check2) && !this.inValidPayableItems());
      if (this.withOutMembership == false) {
        this.withOutMembership = null;
      }
    }
  }

  private toCurrency(value: any) {
    const _value = value || 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD', //USD will added '$' in front of the number
      minimumFractionDigits: 2,
    });
    const formattedCurrency = formatter.format(_value);
    return formattedCurrency;
  }

  get helpText(): any[] {
    return orderBy(this.pcrFormData.paymentDetails.helpTexts, ['priority'], 'asc');
  }

  get lMhelpText(): any {
    const lmMsg = orderBy(
      this.pcrFormData.paymentDetails.helpTexts.filter((x: any) => x.messageType == 'LM'),
      ['priority'],
      'asc',
    );
    if (lmMsg.length > 0) {
      return lmMsg[0];
    } else {
      return {
        message: '',
        messageType: '',
        priority: null,
      };
    }
  }

  get appId() {
    return this.pcrFormData.appId;
  }

  get getTotalFees(): number {
    const items = this.pcrFormData.paymentDetails.lineItems;
    let _total = 0;
    for (let i = 0; i < items.length; i++) {
      _total += this.getPayableItem(items[i].name).amountGstExcluded;
    }
    return _total;
  }

  get getTotalGST(): number {
    const items = this.pcrFormData.paymentDetails.lineItems;
    let _total = 0;
    for (let i = 0; i < items.length; i++) {
      _total += this.getPayableItem(items[i].name).gst;
    }
    return _total;
  }

  get getTotal(): number {
    const items = this.pcrFormData.paymentDetails.lineItems;
    let _total = 0;
    for (let i = 0; i < items.length; i++) {
      _total += this.getPayableItem(items[i].name).total;
    }
    return _total;
  }

  get totalAmountPaid(): number {
    const items = this.pcrFormData.paymentDetails.lineItems;
    let _total = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i].isPaidByPractice) {
        _total += this.getPayableItem(items[i].name).total;
      }
    }
    return _total;
  }

  get paidItems(): any[] {
    return this.lineItems?.filter((x: any) => x.isPaidByPractice);
  }

  get unpaidItems(): any[] {
    return this.lineItems?.filter((x: any) => !x.isPaidByPractice);
  }

  get lastApplicationYearFrom(): string {
    const _lastAppYear = store.getters.getAppInfo.lastApplicationYear?.effectiveStartDate;
    return moment(_lastAppYear).format('YYYY');
  }
}
