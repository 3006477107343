import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { UPDATE_EMAIL_LINK } from '@/shared/config/config';
import { EventEmitter } from "events";
import store from "@/shared/store";
import { AuthorizedUser } from "@/shared/models/authorized-user";
import Popover from "@/shared/components/popover/index.vue";
import PcrWordingData from '@/assets/config/pcr-wording.json';
import PcrCurrentDetailsUpdateModal from "../pcr-current-details-update-modal/index.vue";
import { PopoverView } from "@/shared/components/popover/popover";
import { AddressDetail, Application, PcRenewIndividualDetail } from "@/shared/modules/typescript-api-client/models";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import { RouteN } from "@/shared/constants/route-name";
import AddressPreview from "@/shared/components/address-preview/index.vue";

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading'],
  components: {
    LoadingIndicator,
    Popover,
    PcrCurrentDetailsUpdateModal,
    AddressPreview,
  },
  mixins: [FormatDateTimeMixin],
})
export default class PcrCurrentDetails extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  @Watch('loadingDataSet')
  private sectionLoading() {
    this.$emit('update:isSectionLoading', this.loadingDataSet);
  }

  private emailUpdateInfo = (PcrWordingData[0]["current-details"]["email-update-info"]).replace('--[[UpdateEmailLink]]--', UPDATE_EMAIL_LINK);
  private isDetailsCorrect = NaN;

  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  private practisingCertificate = {};
  private individualDetails: PcRenewIndividualDetail = {};
  private origNames = {};

  private contactPhoneInfoMissing = false;
  private addressMissing = false;

  private showUpdateDetailsModal = false;

  private popovers: PopoverView[] = [];

  @Watch('individualDetails.isCurrentDetailCorrect')
  private radioButtonChange() {
    this.eventCtrl.emit('canGoNext', this.individualDetails.isCurrentDetailCorrect);
  }

  mounted() {
    this.$emit('update:isSectionLoading', true);
    const _origName = store.getters.getPcrFormData.origNames;
    this.origNames = _origName;
    this.getDetails();
  }

  private async getDetails() {
    try {
      const _isRenewing = this.pcrFormData.individualDetails?.pcRenewApplicationId > 0;
      this.loadingDataSet = true;
      this.errorLoadingDataSet = false;
      const resp: any[] = await Promise.all([
        this.apiService.PcRenewApi?.apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(
          this.pcrApp.id, !_isRenewing
        ),
        this.apiService.IndividualApi?.apiV1IndividualGetPractisingCertificateDetailsGet(),
        this.apiService.PageApi?.apiV1PageGetPageContentsPageIdGet(2),
      ]);
      this.individualDetails = resp[0].data.data;
      this.individualDetails.isCurrentDetailCorrect = null;
      if (!_isRenewing) {
        this.origNames = {
          firstName: this.individualDetails.firstName,
          otherName: this.individualDetails.otherName,
          surname: this.individualDetails.surname,
        };
      }
      this.pcrFormData.appId = this.pcrApp.id;
      this.pcrFormData.origNames = this.origNames;
      this.pcrFormData.individualDetails = this.individualDetails;
      this.pcrFormData.individualDetails.noPcHardCopyRequired = !_isRenewing ? true : this.individualDetails.noPcHardCopyRequired == true;
      if (this.individualDetails.mobilePhone.phoneNumber || this.individualDetails.businessPhone.phoneNumber) {
        this.contactPhoneInfoMissing = false;
      } else {
        this.individualDetails.mobilePhone.phoneNumber = '';
        this.individualDetails.businessPhone.phoneNumber = '';
        this.contactPhoneInfoMissing = true;
      }
      this.addressMissing = this.individualDetails.residentialAddress == null;
      this.practisingCertificate = resp[1].data.data;
      for (let p = 0; p < resp[2].data.data.length; p++) {
        this.popovers.push({
          key:resp[2].data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp[2].data.data[p].content as string,
          isShow: resp[2].data.data[p].isShow as boolean,
          title: resp[2].data.data[p].title as string,
        })
      }
    } catch (err: any) {
      this.errorLoadingDataSet = true;
      console.log(err.response?.status, err.response?.data);
      if (err.response?.data && err.response?.data?.errorCode == 10001) {
        this.eventCtrl.emit('show-snackbar', 'info', 'Concurrent application detected');
      } else {
        this.ai.trackException('Error, Unable to get user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get user details, redirect to Portal home');
      }
      this.$router.push({ name: RouteN.Mars.Home.n });
    } finally {
      this.loadingDataSet = false;
    }
  }

  private reloadCurrentDetails() {
    this.apiService.PcRenewApi?.apiV1PcRenewGetIndividualDetailsApplicationIdIsNewApplicationGet(
      this.pcrApp.id, false
    ).then(res => {
      this.individualDetails = res.data.data;
      this.individualDetails.isCurrentDetailCorrect = null;
      this.addressMissing = this.individualDetails.residentialAddress == null;
      this.pcrFormData.individualDetails = this.individualDetails;
      if (this.individualDetails.mobilePhone.phoneNumber || this.individualDetails.businessPhone.phoneNumber) {
        this.contactPhoneInfoMissing = false;
      }
      this.addressMissing = this.individualDetails.residentialAddress == null;
    })
      .catch(err => {
        this.ai.trackException('Error, Unable to reload user details: ' + err);
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to reload user details');
      });
  }

  private updateCurrentDetails() {
    this.individualDetails.residentialAddress = this.individualDetails.residentialAddress ? this.initAddress(this.individualDetails.residentialAddress) : this.initAddress({});
    this.showUpdateDetailsModal = true;
  }

  get userAccount(): AuthorizedUser {
    return store.getters.getUserAccount;
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  get nameCheck() {
    const _firstNameCheck = this.individualDetails?.firstName?.trim() == this.pcrFormData.origNames?.firstName?.trim();
    const _otherNameCheck = this.individualDetails?.otherName?.trim() == this.pcrFormData.origNames?.otherName?.trim();
    const _surnameCheck = this.individualDetails?.surname?.trim() == this.pcrFormData.origNames?.surname?.trim();
    // this.requiredFileUpload = !(_firstNameCheck && _otherNameCheck && _surnameCheck);
    return !(_firstNameCheck && _otherNameCheck && _surnameCheck);
  }

  private initAddress(a: AddressDetail) {
    a.addressTypeId = a.addressTypeId || 1;
    a.buildingName = a.buildingName || '';
    a.buildingNumber = a.buildingNumber || null;
    a.buildingTypeId = a.buildingTypeId || 0;
    a.buildingTypeName = a.buildingTypeName || null
    a.countryId = a.countryId || 187;
    a.countryName = a.countryName || 'AUSTRALIA';
    a.deliveryTypeId = a.deliveryTypeId || 0;
    a.deliveryTypeName = a.deliveryTypeName || null;
    a.dxExchangeTypeId = a.dxExchangeTypeId || 0;
    a.dxExchangeTypeName = a.dxExchangeTypeName || null;
    a.dxNumber = a.dxNumber || null;
    a.foreignAddress = a.foreignAddress || null;
    a.id = a.id || 0;
    a.level = a.level || null;
    a.poBox = a.poBox || null;
    a.postcode = a.postcode || null;
    a.stateId = a.stateId || 0;
    a.stateName = a.stateName || null;
    a.streetName = a.streetName || null;
    a.streetNumber = a.streetNumber || null;
    a.streetTypeId = a.streetTypeId || 0;
    a.streetTypeName = a.streetTypeName || '';
    a.suburbId = a.suburbId || 0;
    a.suburbName = a.suburbName || null;
    a.firmAddMapId = a.firmAddMapId || 0;
    a.postCodeSuburbId = a.postCodeSuburbId || 0;

    return JSON.parse(JSON.stringify(a).replace(/:null/gi, ':""'));
  }

}

