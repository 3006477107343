import { Inject, Options, Vue, Watch } from 'vue-property-decorator';
import SwipeStepper from '@/shared/components/swipe-stepper/index.vue';
import LoadingSpinner from '@/shared/components/loading-spinner/index.vue';
import { RouteN } from '@/shared/constants/route-name';
import store from '@/shared/store';
import { EventEmitter } from 'events';
import {
  NavStep,
  NavStepStatusID,
  Section,
  Step,
} from '@/shared/components/header-stepper/nav-step';
import { MARSApi } from '@/shared/modules/mars-api';
import { AppInsights } from '@/mars-app/appInsights';
import { PaymentTransaction } from '@/shared/models/payment-transaction';
import { PAYMENTAPP_URL } from '@/shared/config/config';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import SmPaymentDetails from '../sm-payment-details/index.vue';
import SmConfirmation from '../sm-confirmation/index.vue';
import SmApplicationDetails from '../sm-application-details/index.vue';
import SmOverview from '../sm-overview/index.vue';
import PaymentPopup from '../../payment-popup/index.vue';
import { Application, PageContent } from '@/shared/modules/typescript-api-client/models';
import { PopoverView } from '@/shared/components/popover/popover';
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import PaymentModal from '../../payment-modal/index.vue';
@Options({
  components: {
    LoadingSpinner,
    SwipeStepper,
    SmApplicationDetails,
    SmConfirmation,
    SmPaymentDetails,
    SmOverview,
    PaymentPopup,
    ConfirmDialogue,
    LoadingIndicator,
    PaymentModal,
  },
  mixins: [FormatDateTimeMixin],
  beforeRouteEnter(to: any, from: any, next: any) {
    const onlineApplication = store.getters.getOnlineApplication;
    if (!onlineApplication.id) {
      console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  },
})
export default class SmApplication extends Vue {
  private ai = new AppInsights();

  @Watch('$route')
  public OnRouteChange(val: any, oldVal: any): void {
    const _stored = store.getters.getSmAppFormData;
    window.scrollTo(0, 0);
    if (oldVal.fullPath.indexOf('confirmation') > -1 || _stored == null) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    this.setCurrentStep();
  }

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private sectionName = '';

  private backButtonVisible = true;
  private canGoNext = false;

  private backButtonText = 'Back';
  private nextButtonText = 'Next';

  private helpContent: PageContent = {};

  private navSteps = [
    new NavStep(Step.applicationDetails, Section.applicationDetails),
    new NavStep(Step.payment, Section.payment),
    new NavStep(Step.confirmation, Section.confirmation),
  ];

  private isLoadingData = false;
  private isSectionLoading = false;

  private smAppFormData: any = {};
  private showPaymentPopup = false;
  private paymentTransaction: PaymentTransaction = new PaymentTransaction();
  private disablePaymentPopupCloseBut = false;
  private validPaymentPage = false;

  private sessionId = new Date().getTime() + '';
  private paymentAppName = 'sm-payment';
  private amountPayable = 0;
  private isSaving = false;
  private popovers: any[] = [];
  private popoverKeys: string[] = [];

  private allPageContents: PageContent = {};
  private loadingDataSet = true;
  private errorLoadingDataSet = false;
  private applyInternalPayment = false;

  mounted(): void {
    document.body.classList.remove('modal-open');
    const _onlineApp: Application = store.getters.getOnlineApplication;
    if (!_onlineApp.id) {
      this.eventCtrl.emit(
        'show-snackbar',
        'error',
        'Invalid Page entry, please choose an option from Portal home',
      );
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    window.scrollTo(0, 0);
    this.setCurrentStep();

    this.eventCtrl.on('canGoNext', this.updateCanGoNext);
    window.addEventListener('message', this.receivedNotification);

    const _section: any = this.$route.query.sec || '';
    this.sectionName = _section;
    this.loadPageContents();
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('canGoNext', this.updateCanGoNext);
    window.removeEventListener('message', this.receivedNotification);
  }

  private receivedNotification(event: any): void {
    const action = event?.data?.action;
    const validOrigin = event?.origin == PAYMENTAPP_URL;
    const validSessionId = event?.data?.sessionId == this.sessionId;
    const validPaymentApp = this.paymentAppName == event?.data?.paymentAppName;

    if (!validOrigin || !validSessionId || !validPaymentApp) {
      return;
    }

    this.processNotification(action, event?.data?.data);
  }

  private processNotification(request: string, dataO: any = null): void {
    switch (request) {
      case 'cancel-payment':
        this.eventCtrl.emit('cancel-sm-application-payment');
        break;
      case 'payment-processed':
        this.paymentConfirmed();
        break;
      case 'parent-close-state':
        this.disablePaymentPopupCloseBut = dataO.disabled;
        break;
      case 'close-payment-form':
        this.showPaymentPopup = false;
        this.validPaymentPage = false;
        this.disablePaymentPopupCloseBut = false;
        break;
      case 'payment-form-isReady':
        this.validPaymentPage = true;
        break;
    }
  }

  private updateCanGoNext(val: boolean): void {
    if (val) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
  }

  private setCurrentStep(): void {
    const _section: string = this.$route.query.sec as string;
    // if (_section !== Section.details) {
    //   this.backButtonText = 'Back';
    // } else {
    //   this.backButtonText = 'Cancel application'
    // }

    if (!_section) {
      this.backButtonText = 'Back';
      this.nextButtonText = 'Next';
    }

    switch (_section) {
      case Section.applicationDetails:
        this.setSteps(0);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.payment:
        this.setSteps(1);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.confirmation:
        this.setSteps(2);
        this.backButtonVisible = false;
        this.nextButtonText = 'Next';
        break;
      default:
        break;
    }
  }

  private setSteps(n: number) {
    this.updateHelpContent(n);
    for (let i = 0; i < this.navSteps.length; i++) {
      this.navSteps[i].status = NavStepStatusID.NotDone;
    }
    for (let i = 0; i < n; i++) {
      this.navSteps[i].status = NavStepStatusID.Done;
    }
    this.navSteps[n].status = NavStepStatusID.Current;
    // the confirmation page
    if (n === 2) {
      for (let i = 0; i < 2; i++) {
        this.navSteps[i].status = NavStepStatusID.Unknown;
      }
    } else {
      // save the current step
      // window.sessionStorage.setItem('contractData', JSON.stringify(this.contractData));
    }
  }

  private updateHelpContent(n: number) {
    switch (n) {
      case 0:
        this.helpContent = this.$getPopover('Help', 8);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 1:
        this.helpContent = this.$getPopover('Help', 24);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 2:
        this.helpContent = this.$getPopover('Help', 25);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      default:
        // this.helpContent = '';
        break;
    }
  }

  private goBack() {
    const _section: string = this.$route.query.sec as string;

    if (this.isOverviewPage) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }

    switch (_section) {
      case Section.applicationDetails:
        // this.eventCtrl.emit('cancel-sm-application');
        this.$router.push({
          name: RouteN.Mars.SmApplication.n,
          query: { appId: this.$route.query.appId },
        });
        break;
      case Section.payment:
        this.navigate(this.navSteps[0]);
        break;
      default:
        break;
    }
  }

  private navigate(s: NavStep): void {
    const temp: string = JSON.stringify(this.$route.query);
    const tempJSON = JSON.parse(temp);
    tempJSON.sec = s.routeSecName;
    this.$router.push({ query: tempJSON });
  }

  private goNext() {
    store.dispatch('setSmAppFormData', this.smAppFormData);
    const _section = this.$route.query.sec as string;

    if (this.isOverviewPage) {
      this.checkConcurrentApplication();
    }

    switch (_section) {
      case Section.applicationDetails:
        this.saveSmApplicationDetails();
        break;
      case Section.payment:
        this.saveSmPaymentDetails();
        break;
      case Section.confirmation:
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }
  }

  private async checkConcurrentApplication() {
    try {
      this.loadingDataSet = true;
      this.errorLoadingDataSet = false;
      await this.apiService.SmApi?.apiV1SmCheckSmConcurrentSessionApplicationIdGet(
        this.onlineApplication.id,
      );

      this.navigate(this.navSteps[0]);
    } catch (err: any) {
      this.errorLoadingDataSet = true;
      if (err.response?.status == 400 && err.response.data?.errorCode == 10001) {
        this.confirmCancelConcurrentApp();
      }
    } finally {
      this.loadingDataSet = false;
    }
  }

  private async saveSmApplicationDetails(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SmApi?.apiV1SmSaveApplicationDetailsPost(
        this.smAppFormData.smIndividualDetails,
      );
      this.eventCtrl.emit('show-snackbar', 'success', 'Application details saved');
      this.navigate(this.navSteps[1]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Application details: ' + err);
      this.ai.trackException('Error, Unable to save Application details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveSmPaymentDetails(): Promise<void> {
    this.isSaving = true;
    try {
      let totalCost = 0;
      let ifAnyPaidByPractice = false;
      this.smAppFormData.paymentDetails?.lineItems.forEach((x: any) => {
        totalCost += x.total;
      });
      ifAnyPaidByPractice = this.smAppFormData.paymentDetails?.lineItems.some(
        (x: any) => x.isPaidByPractice,
      );
      this.amountPayable =
        totalCost -
        this.smAppFormData.paymentDetails?.lineItems
          .filter((x: any) => x.isPaidByPractice)
          .reduce((a: any, b: any) => a + b.total, 0);
      if (!ifAnyPaidByPractice || this.amountPayable === 0) {
        const resp: any = await this.apiService.SmApiV2?.apiV2SmSavePaymentDetailsPost(
          this.smAppFormData.paymentDetails,
        );
        if (resp.data.data.isRequiredToOpenPymtApp) {
          this.paymentTransaction.transactionKey = resp.data.data.transactionKey;
          this.paymentTransaction.secureKey = resp.data.data.secureKey;
          this.eventCtrl.emit('show-snackbar', 'success', 'Payment details saved');
          this.showPaymentPopup = true;
        } else {
          this.navigate(this.navSteps[2]);
        }
      } else {
        // internal payment
        this.applyInternalPayment = true;
      }
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Payment details: ' + err);
      this.ai.trackException('Error, Unable to save Payment details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private paymentConfirmed(): void {
    this.showPaymentPopup = false;
    this.navigate(this.navSteps[2]);
  }

  private getPopOverKeys(): void {
    const _keys = [];
    for (let p = 0; p < this.popoverKeys.length; p++) {
      if (this.popoverKeys[p + 1]) {
        const _stringEndAt = this.popoverKeys[p + 1].indexOf('}}--');
        _keys.push(this.popoverKeys[p + 1].substring(0, _stringEndAt));
      }
    }
    this.popoverKeys = _keys;
  }

  private getPopover(pKey: string): PopoverView {
    const _popOver = this.popovers.filter((p) => {
      return p.popoverKey == pKey;
    })[0];
    return _popOver;
  }

  private async loadPageContents() {
    // const _appId = parseInt(this.$route.query.appId as string);
    try {
      this.loadingDataSet = true;
      this.errorLoadingDataSet = false;
      const resp: any[] = await Promise.all([
        this.apiService.PageApi?.apiV1PageGetPagesGet(),
        this.apiService.CodeApi?.apiV1CodeGetContentTypesGet(),
        this.apiService.PageApi?.apiV1PageGetAllPageContentsGet(),
      ]);

      for (let p = 0; p < resp[2].data.data.length; p++) {
        this.popovers.push({
          key: resp[2].data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp[2].data.data[p].content as string,
          isShow: resp[2].data.data[p].isShow as boolean,
          title: resp[2].data.data[p].title as string,
          pageId: resp[2].data.data[p].pageId as number,
        });
      }

      this.getPopOverKeys();

      store.dispatch('setPopovers', this.popovers);
    } catch (err) {
      this.errorLoadingDataSet = true;
      this.ai.trackException('Error, Unable to load page contents: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private confirmCancelConcurrentApp() {
    this.loadingDataSet = true;
    const _confirm: any = this.$refs.confirmCancelCurrentDialogue;
    _confirm
      .show({
        title: 'You are already logged in elsewhere',
        message:
          'You can only be logged in on one browser at a time. If you wish to <b>continue here</b>, please select "Continue" if not, select "Cancel". <p/><div style="color: red">Please note that, if you wish to continue on this browser, you will be restarting your application. Do you still wish to continue your application here? </div>',
        okButton: 'Continue',
        cancelButton: 'Cancel',
      })
      .then(async (res: any) => {
        if (res) {
          this.clearSessionAndStart();
        } else {
          this.$router.push({ name: RouteN.Mars.Home.n });
        }
      });
  }

  private async clearSessionAndStart(): Promise<void> {
    try {
      this.loadingDataSet = true;
      await this.apiService.SmApi?.apiV1SmCancelSmApplicationApplicationIdGet(
        this.onlineApplication.id,
      );
      this.loadingDataSet = false;
      this.navigate(this.navSteps[0]);
    } catch {
      this.loadingDataSet = false;
    }
  }

  get isOverviewPage(): boolean {
    return !this.$route.query.sec && this.$route.name == RouteN.Mars.SmApplication.n;
  }

  get $Section() {
    return Section;
  }

  get navStepsData(): NavStep[] {
    return this.navSteps;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get currentSection(): string {
    return this.$route.query.sec as string;
  }
}
