/* tslint:disable */
/* eslint-disable */
/**
 * MARS API
 * The Mars Api with Swagger, Swashbuckle, and API Versioning.
 *
 * OpenAPI spec version: 2.0
 * Contact: hitesh.patel@lawsociety.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { PcRenewPaymentDetailsRequest } from '../models';
import { PcRenewPaymentDetailsResponse } from '../models';
import { TransactionResponse } from '../models';
/**
 * PcRenewApi - axios parameter creator
 * @export
 */
export const PcRenewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2PcRenewGetPaymentDetailsApplicationIdGet: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiV2PcRenewGetPaymentDetailsApplicationIdGet.');
            }
            const localVarPath = `/api/v2/PcRenew/GetPaymentDetails/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2PcRenewSavePaymentDetailsPost: async (body?: PcRenewPaymentDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/PcRenew/SavePaymentDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {ApplicationId}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2PcRenewUpdateApplicationStatusPut: async (applicationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/PcRenew/UpdateApplicationStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof applicationId !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(applicationId !== undefined ? applicationId : {}) : (applicationId || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PcRenewApi - functional programming interface
 * @export
 */
export const PcRenewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PcRenewPaymentDetailsResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV2PcRenewSavePaymentDetailsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary External | Update Application Status
         * @param {applicationId}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2PcRenewUpdateApplicationStatusPut(applicationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await PcRenewApiAxiosParamCreator(configuration).apiV2PcRenewUpdateApplicationStatusPut(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PcRenewApi - factory interface
 * @export
 */
export const PcRenewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary External | Get Payment Details
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any): AxiosPromise<PcRenewPaymentDetailsResponse> {
            return PcRenewApiFp(configuration).apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary External | Save Payment Details
         * @param {PcRenewPaymentDetailsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any): AxiosPromise<TransactionResponse> {
            return PcRenewApiFp(configuration).apiV2PcRenewSavePaymentDetailsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PcRenewApi - object-oriented interface
 * @export
 * @class PcRenewApi
 * @extends {BaseAPI}
 */
export class PcRenewApi extends BaseAPI {
    /**
     * 
     * @summary External | Get Payment Details
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV2PcRenewGetPaymentDetailsApplicationIdGet(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Save Payment Details
     * @param {PcRenewPaymentDetailsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV2PcRenewSavePaymentDetailsPost(body?: PcRenewPaymentDetailsRequest, options?: any) {
        return PcRenewApiFp(this.configuration).apiV2PcRenewSavePaymentDetailsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary External | Update Application Status
     * @param {applicationId}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PcRenewApi
     */
    public apiV2PcRenewUpdateApplicationStatusPut(applicationId?: number, options?: any) {
        return PcRenewApiFp(this.configuration).apiV2PcRenewUpdateApplicationStatusPut(applicationId, options).then((request) => request(this.axios, this.basePath));
    }
}
