import globalAxios, { AxiosInstance } from "axios";
import {
  SystemApi,
  ApplicationApi,
  IndividualApi,
  Configuration,
  PageApi,
  CodeApi,
  PcRenewApi,
  FileApi,
  PcNonRenewApi,
  PaymentApi,
  SmApi,
  AmRenewApi,
  AmNewApi,
  SpecAccRenewApi,
  SpecAccNonRenewApi,
  StudentNewApi,
  StudentRenewApi,
  AdminApi,
} from "@/shared/modules/typescript-api-client";
import {
  PcRenewApi as PcRenewApiV2,
  SmApi as SmApiV2,
  SpecAccRenewApi as SpecAccRenewApiV2,
} from "@/shared/modules/typescript-api-client-v2/index";
export const BASE_PATH = "/".replace(/\/+$/, "");

export class MARSApi {
  public ApplicationApi?: ApplicationApi;
  public IndividualApi?: IndividualApi;
  public SystemApi?: SystemApi;
  public PageApi?: PageApi;
  public CodeApi?: CodeApi;
  public PcRenewApi?: PcRenewApi;
  public FileApi?: FileApi;
  public PcNonRenewApi?: PcNonRenewApi;
  public PaymentApi?: PaymentApi;
  public SmApi?: SmApi;
  public AmRenewApi?: AmRenewApi;
  public AmNewApi?: AmNewApi;
  public SpecAccRenewApi?: SpecAccRenewApi;
  public SpecAccNonRenewApi?: SpecAccNonRenewApi;
  public StudentNewApi?: StudentNewApi;
  public StudentRenewApi?: StudentRenewApi;
  public AdminApi?: AdminApi;
  public PcRenewApiV2?: PcRenewApiV2;
  public SmApiV2?: SmApiV2;
  public SpecAccRenewApiV2?: SpecAccRenewApiV2;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    this.ApplicationApi = new ApplicationApi(configuration, basePath, axios);
    this.IndividualApi = new IndividualApi(configuration, basePath, axios);
    this.SystemApi = new SystemApi(configuration, basePath, axios);
    this.PageApi = new PageApi(configuration, basePath, axios);
    this.CodeApi = new CodeApi(configuration, basePath, axios);
    this.PcRenewApi = new PcRenewApi(configuration, basePath, axios);
    this.FileApi = new FileApi(configuration, basePath, axios);
    this.PcNonRenewApi = new PcNonRenewApi(configuration, basePath, axios);
    this.PaymentApi = new PaymentApi(configuration, basePath, axios);
    this.SmApi = new SmApi(configuration, basePath, axios);
    this.AmRenewApi = new AmRenewApi(configuration, basePath, axios);
    this.AmNewApi = new AmNewApi(configuration, basePath, axios);
    this.SpecAccRenewApi = new SpecAccRenewApi(configuration, basePath, axios);
    this.SpecAccNonRenewApi = new SpecAccNonRenewApi(
      configuration,
      basePath,
      axios
    );
    this.StudentNewApi = new StudentNewApi(configuration, basePath, axios);
    this.StudentRenewApi = new StudentRenewApi(configuration, basePath, axios);
    this.AdminApi = new AdminApi(configuration, basePath, axios);
    this.PcRenewApiV2 = new PcRenewApiV2(configuration, basePath, axios);
    this.SmApiV2 = new SmApiV2(configuration, basePath, axios);
    this.SpecAccRenewApiV2 = new SpecAccRenewApiV2(
      configuration,
      basePath,
      axios
    );
  }
}
