import { Inject, Options, Vue, Watch } from "vue-property-decorator";
import store from "@/shared/store";
import { AppInsights } from "@/mars-app/appInsights";
import { MARSApi } from "@/shared/modules/mars-api";
import { EventEmitter } from "events";
import LoadingIndicator from "@/shared/components/loading-indicator/index.vue";
import { Application, NonRenewalReason } from "@/shared/modules/typescript-api-client/models";
import DatePicker from 'vue-datepicker-next';
import Popover from "@/shared/components/popover/index.vue";
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';

@Options({
  props: {
    pcrFormData: Object,
    isSectionLoading: Boolean,
  },
  emits: ['update:isSectionLoading', 'update:pcrFormData'],
  components: {
    LoadingIndicator,
    DatePicker,
    Popover,
  },
  mixins: [
    FormatDateTimeMixin,
  ]
})
export default class PcrSpecialistAccreditation extends Vue {
  private pcrFormData!: any;
  private isSectionLoading!: boolean;

  private ai = new AppInsights;
  private loadingDataSet = true;
  private sarRecordLinkedAreaOfLaws: any[] = [];
  private specAccRenewalFlag = 0;
  private nonRenewalReasons = [] as any;
  private selectedReason: NonRenewalReason = null as any;
  private reasons = '';
  private leaveStartDate: Date | null = null;
  private leaveReturnDate: Date | null = null;
  private dateRangeSelected: [Date | null, Date | null] = [null, null];

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }
  get currentApplicationYear(): string {
    return store.getters.getAppInfo.applicationYear?.name;
  }

  get lastApplicationYear(): number {
    return store.getters.getAppInfo.lastApplicationYear.year;
  }


  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag1')
  private onRenewalFlag1Changed(): void {
    if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag1) {
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag2 = false;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag21 = null;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag22 = null;
      this.pcrFormData.sarRecordLinkedApplication.nonRenewalReasonId = null;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag3 = false;
    }
    this.canGoNextCheck();
  }

  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag2')
  private onRenewalFlag2Changed(): void {
    if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag2) {
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag1 = false;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag3 = false;
    }
    this.canGoNextCheck();
  }

  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag21')
  private onRenewalFlag21Changed(): void {
    if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag21) {
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag22 = null;
      this.pcrFormData.sarRecordLinkedApplication.nonRenewalReasonId = null;
      this.selectedReason = null as any;
      this.reasons = null as any;
      this.leaveStartDate = null as any;
      this.leaveReturnDate = null as any;
      this.dateRangeSelected = [this.leaveStartDate, this.leaveReturnDate];
    }
    this.canGoNextCheck();
  }

  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag22')
  private onRenewalFlag22Changed(): void {
    if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag22) {
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag21 = false;
      this.pcrFormData.sarRecordLinkedApplication.nonRenewalReasonId = null;
      this.selectedReason = null as any;
      this.reasons = null as any;
      this.leaveStartDate = null as any;
      this.leaveReturnDate = null as any;
      this.dateRangeSelected = [this.leaveStartDate, this.leaveReturnDate];
    }
    this.canGoNextCheck();
  }

  @Watch('selectedReason')
  private onSelectedReasonChanged(): void {
    this.pcrFormData.sarRecordLinkedApplication.nonRenewalReasonId = this.selectedReason?.id ?? null;
    this.canGoNextCheck();
  }

  @Watch('pcrFormData.sarRecordLinkedApplication.renewalFlag3')
  private onRenewalFlag3Changed(): void {
    if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag3) {
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag1 = false;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag2 = false;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag21 = null;
      this.pcrFormData.sarRecordLinkedApplication.renewalFlag22 = null;
      this.pcrFormData.sarRecordLinkedApplication.nonRenewalReasonId = null;
    }
    this.canGoNextCheck();
  }

  @Watch('reasons')
  private onReasonsChanged(): void {
    this.pcrFormData.sarRecordLinkedApplication.reasons = this.reasons;
    this.canGoNextCheck();
  }

  @Watch('dateRangeSelected')
  private onDateRangeSelectedChanged(): void {
    this.pcrFormData.sarRecordLinkedApplication.leaveStartDate = this.dateRangeSelected[0];
    this.pcrFormData.sarRecordLinkedApplication.leaveReturnDate = this.dateRangeSelected[1];
    this.canGoNextCheck();
  }

  mounted(): void {
    this.preloadData();
    this.canGoNextCheck();
  }

  private async preloadData(): Promise<void> {
    try {
      this.loadingDataSet = true;
      const resp: any = await Promise.all([
        this.apiService.CodeApi?.apiV1CodeGetSpecAccNonRenewalReasonsGet(),
      ]);
      await this.loadData();
      this.nonRenewalReasons = resp[0].data.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.canGoNextCheck();
      this.loadingDataSet = false;
    }
  }

  private async loadData(): Promise<void> {
    this.loadingDataSet = true;
    await this.apiService.PcRenewApi?.apiV1PcRenewGetSarRecordLinkedApplicationApplicationIdGet(this.pcrApp.id)
      .then(res => {
        this.pcrFormData.sarRecordLinkedApplication = res.data.data;
        this.sarRecordLinkedAreaOfLaws = this.pcrFormData.sarRecordLinkedApplication.sarRecordLinkedAreaOfLaws ?? [];
        this.selectedReason = this.pcrFormData.sarRecordLinkedApplication.sarRecordLinkedNonRenewalDetail?.nonRenewalReason ?? null as any;
        this.reasons = this.pcrFormData.sarRecordLinkedApplication.sarRecordLinkedLeaveOfAbsence?.reasons ?? '';
        this.leaveStartDate = new Date(this.pcrFormData.sarRecordLinkedApplication.sarRecordLinkedLeaveOfAbsence?.leaveStartDate) ?? null;
        this.leaveReturnDate = new Date(this.pcrFormData.sarRecordLinkedApplication.sarRecordLinkedLeaveOfAbsence?.leaveReturnDate) ?? null;
        this.dateRangeSelected = [this.leaveStartDate, this.leaveReturnDate];
        this.loadingDataSet = false;
      })
      .catch(err => {
        this.loadingDataSet = false;
        this.eventCtrl.emit('show-snackbar', 'error', 'Unable to get linked spec acc record: ' + err);
        this.ai.trackException('Error, Unable to get linked spec acc records: ' + err);
      })
  }

  get pcrApp(): Application {
    return store.getters.getAppRenew;
  }

  private isValidForm(): boolean {
    if (this.pcrFormData.sarRecordLinkedApplication?.renewalFlag1) {
      return this.pcrFormData.sarRecordLinkedApplication?.renewalFlag1;
    }
    else if (this.pcrFormData.sarRecordLinkedApplication?.renewalFlag2) {
      if (this.pcrFormData.sarRecordLinkedApplication.renewalFlag21) {
        return this.pcrFormData.sarRecordLinkedApplication?.renewalFlag21 && this.pcrFormData.sarRecordLinkedApplication?.leaveStartDate && this.pcrFormData.sarRecordLinkedApplication?.leaveReturnDate && this.pcrFormData.sarRecordLinkedApplication?.reasons;
      } else {
        return this.pcrFormData.sarRecordLinkedApplication?.renewalFlag22 && this.pcrFormData.sarRecordLinkedApplication?.nonRenewalReasonId > 0;
      }
    }
    else if (this.pcrFormData.sarRecordLinkedApplication?.renewalFlag3) {
      return this.pcrFormData.sarRecordLinkedApplication?.renewalFlag3;
    }
    return false;
  }

  private canGoNextCheck() {
    this.eventCtrl.emit('canGoNext', this.isValidForm());
  }

}


