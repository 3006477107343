import { Inject, Options, Vue, Watch } from 'vue-property-decorator';
import SwipeStepper from '@/shared/components/swipe-stepper/index.vue';
import LoadingSpinner from '@/shared/components/loading-spinner/index.vue';
import { RouteN } from '@/shared/constants/route-name';
import store from '@/shared/store';
import { EventEmitter } from 'events';
import {
  NavStep,
  NavStepStatusID,
  Section,
  Step,
} from '@/shared/components/header-stepper/nav-step';
import { MARSApi } from '@/shared/modules/mars-api';
import { AppInsights } from '@/mars-app/appInsights';
import { PaymentTransaction } from '@/shared/models/payment-transaction';
import { PAYMENTAPP_URL } from '@/shared/config/config';
import LoadingIndicator from '@/shared/components/loading-indicator/index.vue';
import SarOverview from '../sar-overview/index.vue';
import PaymentPopup from '../../payment-popup/index.vue';
import { Application, PageContent } from '@/shared/modules/typescript-api-client/models';
import { PopoverView } from '@/shared/components/popover/popover';
import FormatDateTimeMixin from '@/shared/mixins/format-datetime';
import ConfirmDialogue from '@/shared/components/confirm-dialogue/index.vue';
import SarCurrentDetails from '../sar-current-details/index.vue';
import SarConfirmation from '../sar-confirmation/index.vue';
import SarPaymentDetails from '../sar-payment-details/index.vue';
import SarRenewalDeclarations from '../sar-renewal-declarations/index.vue';
import SarApplicationDeclarations from '../sar-application-declarations/index.vue';
import AlertBoxTypeTwo from '@/shared/components/alert-box-type-two/index.vue';
import PaymentModal from '../../payment-modal/index.vue';
@Options({
  components: {
    LoadingSpinner,
    SwipeStepper,
    SarRenewalDeclarations,
    SarApplicationDeclarations,
    SarCurrentDetails,
    SarConfirmation,
    SarPaymentDetails,
    SarOverview,
    PaymentPopup,
    ConfirmDialogue,
    LoadingIndicator,
    AlertBoxTypeTwo,
    PaymentModal,
  },
  mixins: [FormatDateTimeMixin],
  beforeRouteEnter(to: any, from: any, next: any) {
    const onlineApplication = store.getters.getOnlineApplication;
    if (!onlineApplication.id) {
      console.log('Invalid Page entry: ', to);
      next({ name: RouteN.Mars.Home.n });
    } else {
      next();
    }
  },
})
export default class SarApplication extends Vue {
  private ai = new AppInsights();

  @Watch('$route')
  public OnRouteChange(val: any, oldVal: any): void {
    const _stored = store.getters.getSarAppFormData;
    window.scrollTo(0, 0);
    if (oldVal.fullPath.indexOf('confirmation') > -1 || _stored == null) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    this.setCurrentStep();
  }

  @Watch('sarAppFormData', { deep: true })
  private OnSarAppFormDataChange(): void {
    if (this.sarAppFormData?.sarOverview?.decision == 1) {
      this.nextButtonText = 'Submit';
    } else {
      this.nextButtonText = 'Next';
    }
  }

  @Inject({ from: 'apiService', default: new MARSApi() })
  private apiService!: MARSApi;

  private sectionName = '';

  private backButtonVisible = true;
  private canGoNext = false;

  private backButtonText = 'Back';
  private nextButtonText = 'Next';

  private helpContent: PageContent = {};

  private navSteps = [
    new NavStep(Step.currentDetails, Section.currentDetails),
    new NavStep(Step.applicationDeclarations, Section.applicationDeclarations),
    new NavStep(Step.renewalDeclarations, Section.renewalDeclarations),
    new NavStep(Step.payment, Section.payment),
    new NavStep(Step.confirmation, Section.confirmation),
  ];

  private isLoadingData = false;
  private isSectionLoading = false;

  private sarAppFormData: any = {};
  private showPaymentPopup = false;
  private paymentTransaction: PaymentTransaction = new PaymentTransaction();
  private disablePaymentPopupCloseBut = false;
  private validPaymentPage = false;

  private sessionId = new Date().getTime() + '';
  private paymentAppName = 'sar-payment';
  private amountPayable = 0;
  private applyInternalPayment = false;
  private isSaving = false;
  private popovers: any[] = [];
  private popoverKeys: string[] = [];

  private allPageContents: PageContent = {};
  private loadingDataSet = true;
  private errorLoadingDataSet = false;

  mounted(): void {
    document.body.classList.remove('modal-open');
    const _onlineApp: Application = store.getters.getOnlineApplication;
    if (!_onlineApp.id) {
      this.eventCtrl.emit(
        'show-snackbar',
        'error',
        'Invalid Page entry, please choose an option from Portal home',
      );
      this.$router.push({ name: RouteN.Mars.Home.n });
    }
    window.scrollTo(0, 0);
    this.setCurrentStep();

    this.eventCtrl.on('canGoNext', this.updateCanGoNext);
    window.addEventListener('message', this.receivedNotification);

    const _section: any = this.$route.query.sec || '';
    this.sectionName = _section;
    this.loadPageContents();
  }

  beforeUnmount(): void {
    this.eventCtrl.removeListener('canGoNext', this.updateCanGoNext);
    window.removeEventListener('message', this.receivedNotification);
  }

  private receivedNotification(event: any): void {
    const action = event?.data?.action;
    const validOrigin = event?.origin == PAYMENTAPP_URL;
    const validSessionId = event?.data?.sessionId == this.sessionId;
    const validPaymentApp = this.paymentAppName == event?.data?.paymentAppName;

    // console.log('Received Notifications -- MARSApp');
    // console.log(action);
    // console.log(event?.origin, PAYMENTAPP_URL);
    // console.log(event?.data?.sessionId, this.sessionId);
    // console.log(event?.data?.paymentAppName, this.paymentAppName);
    // console.log('failed verification', !validOrigin , !validSessionId , !validPaymentApp);
    if (!validOrigin || !validSessionId || !validPaymentApp) {
      return;
    }

    this.processNotification(action, event?.data?.data);
  }

  private processNotification(request: string, dataO: any = null): void {
    switch (request) {
      case 'cancel-payment':
        this.eventCtrl.emit('cancel-sar-application-payment');
        break;
      case 'payment-processed':
        this.paymentConfirmed();
        break;
      case 'parent-close-state':
        this.disablePaymentPopupCloseBut = dataO.disabled;
        break;
      case 'close-payment-form':
        this.showPaymentPopup = false;
        this.validPaymentPage = false;
        this.disablePaymentPopupCloseBut = false;
        break;
      case 'payment-form-isReady':
        this.validPaymentPage = true;
        break;
    }
  }

  private updateCanGoNext(val: boolean): void {
    if (val) {
      this.canGoNext = true;
    } else {
      this.canGoNext = false;
    }
  }

  private setCurrentStep(): void {
    const _section: string = this.$route.query.sec as string;
    // if (_section !== Section.details) {
    //   this.backButtonText = 'Back';
    // } else {
    //   this.backButtonText = 'Cancel application'
    // }

    if (!_section) {
      this.backButtonText = 'Back';
      if (this.sarAppFormData?.sarOverview?.decision == 1) {
        this.nextButtonText = 'Submit';
      } else {
        this.nextButtonText = 'Next';
      }
    }

    switch (_section) {
      case Section.currentDetails:
        this.setSteps(0);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.applicationDeclarations:
        this.setSteps(1);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.renewalDeclarations:
        this.setSteps(2);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.payment:
        this.setSteps(3);
        this.backButtonVisible = true;
        this.nextButtonText = 'Next';
        break;
      case Section.confirmation:
        this.setSteps(4);
        this.backButtonVisible = false;
        this.nextButtonText = 'Next';
        break;
      default:
        break;
    }
  }

  private setSteps(n: number) {
    this.updateHelpContent(n);
    for (let i = 0; i < this.navSteps.length; i++) {
      this.navSteps[i].status = NavStepStatusID.NotDone;
    }
    for (let i = 0; i < n; i++) {
      this.navSteps[i].status = NavStepStatusID.Done;
    }
    this.navSteps[n].status = NavStepStatusID.Current;
    // the confirmation page
    if (n === 4) {
      for (let i = 0; i < 4; i++) {
        this.navSteps[i].status = NavStepStatusID.Unknown;
      }
    } else {
      // save the current step
      // window.sessionStorage.setItem('contractData', JSON.stringify(this.contractData));
    }
  }

  private updateHelpContent(n: number) {
    switch (n) {
      case 0:
        this.helpContent = this.$getPopover('Help', 10);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 1:
        this.helpContent = this.$getPopover('Help', 11);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 2:
        this.helpContent = this.$getPopover('Help', 31);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 3:
        this.helpContent = this.$getPopover('Help', 32);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      case 4:
        this.helpContent = this.$getPopover('Help', 33);
        this.eventCtrl.emit('updateHelpContent', this.helpContent);
        break;
      default:
        // this.helpContent = '';
        break;
    }
  }

  private goBack() {
    const _section: string = this.$route.query.sec as string;

    if (this.isOverviewPage) {
      this.$router.push({ name: RouteN.Mars.Home.n });
    }

    switch (_section) {
      case Section.currentDetails:
        this.eventCtrl.emit('cancel-sar-application');
        break;
      case Section.applicationDeclarations:
        this.navigate(this.navSteps[0]);
        break;
      case Section.renewalDeclarations:
        this.navigate(this.navSteps[1]);
        break;
      case Section.payment:
        this.navigate(this.navSteps[2]);
        break;
      default:
        break;
    }
  }

  private navigate(s: NavStep): void {
    const temp: string = JSON.stringify(this.$route.query);
    const tempJSON = JSON.parse(temp);
    tempJSON.sec = s.routeSecName;
    this.$router.push({ query: tempJSON });
  }

  private goNext() {
    store.dispatch('setSarAppFormData', this.sarAppFormData);
    const _section = this.$route.query.sec as string;

    if (this.isOverviewPage) {
      if (this.sarAppFormData?.sarOverview?.decision != null) {
        this.checkConcurrentApplication();
      }
    }

    switch (_section) {
      case Section.currentDetails:
        // this.navigate(this.navSteps[1]);
        this.saveSarCurrentDetails();
        break;
      case Section.applicationDeclarations:
        // this.navigate(this.navSteps[2]);
        this.saveSarApplicationDeclaration();
        break;
      case Section.renewalDeclarations:
        // this.navigate(this.navSteps[3]);
        this.saveSarRenewalDeclaration();
        break;
      case Section.payment:
        this.saveSarPaymentDetails();
        // this.navigate(this.navSteps[4]);
        break;
      case Section.confirmation:
        this.$router.push({ name: RouteN.Mars.Home.n });
        break;
      default:
        break;
    }
  }

  private async checkConcurrentApplication() {
    const nonRenewType = this.sarAppFormData?.sarOverview?.nonRenewType;
    try {
      if (this.sarAppFormData?.sarOverview?.decision == 1) {
        this.isSaving = true;
      } else {
        this.loadingDataSet = true;
        this.errorLoadingDataSet = false;
      }

      await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewCheckConcurrentSessionApplicationIdGet(
        this.onlineApplication.id,
      );

      if (this.sarAppFormData?.sarOverview?.decision == 1) {
        if (this.sarAppFormData?.sarOverview?.nonRenewType == true) {
          this.applyForleaveOfAbsence();
        } else if (
          this.sarAppFormData?.sarOverview?.nonRenewType == false &&
          this.sarAppFormData?.sarOverview?.nonRenewType != null
        ) {
          this.applyForNonRenewal();
        }
      } else {
        this.navigate(this.navSteps[0]);
      }
    } catch (err: any) {
      if (this.sarAppFormData?.sarOverview?.decision == 0) {
        this.errorLoadingDataSet = true;
      }

      if (err.response?.status == 400 && err.response.data?.errorCode == 10001) {
        this.confirmCancelConcurrentApp();
      }
    } finally {
      if (this.sarAppFormData?.sarOverview?.decision == 0) {
        this.loadingDataSet = false;
      }
    }
  }

  private async applyForleaveOfAbsence(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SpecAccNonRenewApi?.apiV1SpecAccNonRenewSaveLeaveOfAbsencePost(
        this.sarAppFormData.sarOverview.leaveOfAbsence,
      );
      this.eventCtrl.emit('show-snackbar', 'success', 'Apply for leave of absence submitted');
      this.navigate(this.navSteps[4]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to submit leave of absense: ' + err);
      this.ai.trackException('Error, Unable to submit leave of absense: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async applyForNonRenewal(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SpecAccNonRenewApi?.apiV1SpecAccNonRenewSaveNonRenewalDetailsPost(
        this.sarAppFormData.sarOverview.nonRenewalDetails,
      );
      this.eventCtrl.emit('show-snackbar', 'success', 'Apply for leave of absence submitted');
      this.navigate(this.navSteps[4]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to submit leave of absense: ' + err);
      this.ai.trackException('Error, Unable to submit leave of absense: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveSarCurrentDetails(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewSaveCurrentDetailsChangedPost({
        sarApplicationId: this.sarAppFormData.currentDetails.sarApplicationId,
        isCurrentDetailsChanged:
          this.sarAppFormData.currentDetails.sarApplication.isCurrentDetailsChanged,
      });
      this.eventCtrl.emit('show-snackbar', 'success', 'Application details saved');
      this.navigate(this.navSteps[1]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save application details: ' + err);
      this.ai.trackException('Error, Unable to save application details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveSarApplicationDeclaration(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewSaveApplicationDeclarationPost(
        this.sarAppFormData.sarDeclaration,
      );
      this.eventCtrl.emit('show-snackbar', 'success', 'Application declaration saved');
      this.navigate(this.navSteps[2]);
    } catch (err) {
      this.eventCtrl.emit(
        'show-snackbar',
        'error',
        'Unable to save Application declaration: ' + err,
      );
      this.ai.trackException('Error, Unable to save Application declaration: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveSarRenewalDeclaration(): Promise<void> {
    this.isSaving = true;
    try {
      await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewSaveRenewalDeclarationPost({
        sarApplicationId: this.sarAppFormData.currentDetails.sarApplicationId,
      });
      this.eventCtrl.emit('show-snackbar', 'success', 'Renewal declaration saved');
      this.navigate(this.navSteps[3]);
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Renewal declaration: ' + err);
      this.ai.trackException('Error, Unable to save Renewal declaration: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private async saveSarPaymentDetails(): Promise<void> {
    this.isSaving = true;
    try {
      let totalCost = 0;
      let ifAnyPaidByPractice = false;
      this.sarAppFormData.paymentDetails?.lineItems.forEach((x: any) => {
        totalCost += x.total;
      });
      ifAnyPaidByPractice = this.sarAppFormData.paymentDetails?.lineItems.some(
        (x: any) => x.isPaidByPractice,
      );
      this.amountPayable =
        totalCost -
        this.sarAppFormData.paymentDetails?.lineItems
          .filter((x: any) => x.isPaidByPractice)
          .reduce((a: any, b: any) => a + b.total, 0);
      if (!ifAnyPaidByPractice || this.amountPayable === 0) {
        const resp: any =
          await this.apiService.SpecAccRenewApiV2?.apiV2SpecAccRenewSavePaymentDetailsPost(
            this.sarAppFormData.paymentDetails,
          );
        if (resp.data.data.isRequiredToOpenPymtApp) {
          this.paymentTransaction.transactionKey = resp.data.data.transactionKey;
          this.paymentTransaction.secureKey = resp.data.data.secureKey;
          this.eventCtrl.emit('show-snackbar', 'success', 'Payment details saved');
          this.showPaymentPopup = true;
        } else {
          this.navigate(this.navSteps[11]);
        }
      } else {
        // internal payment
        this.applyInternalPayment = true;
      }
    } catch (err) {
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to save Payment details: ' + err);
      this.ai.trackException('Error, Unable to save Payment details: ' + err);
    } finally {
      this.isSaving = false;
    }
  }

  private paymentConfirmed(): void {
    this.showPaymentPopup = false;
    this.navigate(this.navSteps[4]);
  }

  private getPopOverKeys(): void {
    const _keys = [];
    for (let p = 0; p < this.popoverKeys.length; p++) {
      if (this.popoverKeys[p + 1]) {
        const _stringEndAt = this.popoverKeys[p + 1].indexOf('}}--');
        _keys.push(this.popoverKeys[p + 1].substring(0, _stringEndAt));
      }
    }
    this.popoverKeys = _keys;
  }

  private getPopover(pKey: string): PopoverView {
    const _popOver = this.popovers.filter((p) => {
      return p.popoverKey == pKey;
    })[0];
    return _popOver;
  }

  private async loadPageContents() {
    // const _appId = parseInt(this.$route.query.appId as string);
    try {
      // this.loadingDataSet = true;
      // this.errorLoadingDataSet = false;
      const resp: any[] = await Promise.all([
        this.apiService.PageApi?.apiV1PageGetPagesGet(),
        this.apiService.CodeApi?.apiV1CodeGetContentTypesGet(),
        this.apiService.PageApi?.apiV1PageGetAllPageContentsGet(),
      ]);

      for (let p = 0; p < resp[2].data.data.length; p++) {
        this.popovers.push({
          key: resp[2].data.data[p].key.replace('[[', '').replace(']]', '') as string,
          content: resp[2].data.data[p].content as string,
          isShow: resp[2].data.data[p].isShow as boolean,
          title: resp[2].data.data[p].title as string,
          pageId: resp[2].data.data[p].pageId as number,
        });
      }
      this.getPopOverKeys();
      store.dispatch('setPopovers', this.popovers);
    } catch (err) {
      this.errorLoadingDataSet = true;
      this.ai.trackException('Error, Unable to load page contents: ' + err);
      this.eventCtrl.emit('show-snackbar', 'error', 'Unable to load page contents' + err);
    } finally {
      this.loadingDataSet = false;
    }
  }

  private confirmCancelConcurrentApp() {
    if (this.sarAppFormData?.sarOverview?.decision == 1) {
      this.isSaving = true;
    } else {
      this.loadingDataSet = true;
    }

    const _confirm: any = this.$refs.confirmCancelCurrentDialogue;
    _confirm
      .show({
        title: 'You are already logged in elsewhere',
        message:
          'You can only be logged in on one browser at a time. If you wish to <b>continue here</b>, please select "Continue" if not, select "Cancel". <p/><div style="color: red">Please note that, if you wish to continue on this browser, you will be restarting your application. Do you still wish to continue your application here? </div>',
        okButton: 'Continue',
        cancelButton: 'Cancel',
      })
      .then(async (res: any) => {
        if (res) {
          this.clearSessionAndStart();
        } else {
          this.$router.push({ name: RouteN.Mars.Home.n });
        }
      });
  }

  private async clearSessionAndStart(): Promise<void> {
    try {
      if (this.sarAppFormData?.sarOverview?.decision == 1) {
        this.isSaving = true;
      } else {
        this.loadingDataSet = true;
      }

      await this.apiService.SpecAccRenewApi?.apiV1SpecAccRenewCancelApplicationApplicationIdGet(
        this.onlineApplication.id,
      );

      if (this.sarAppFormData?.sarOverview?.decision == 1) {
        if (this.sarAppFormData?.sarOverview?.nonRenewType == true) {
          this.applyForleaveOfAbsence();
        } else if (
          this.sarAppFormData?.sarOverview?.nonRenewType == false &&
          this.sarAppFormData?.sarOverview?.nonRenewType != null
        ) {
          this.applyForNonRenewal();
        }
      } else {
        this.loadingDataSet = false;
        this.navigate(this.navSteps[0]);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  get isOverviewPage(): boolean {
    return !this.$route.query.sec && this.$route.name == RouteN.Mars.SarApplication.n;
  }

  get $Section() {
    return Section;
  }

  get navStepsData(): NavStep[] {
    return this.navSteps;
  }

  get eventCtrl(): EventEmitter {
    return store.getters.getEventController;
  }

  get onlineApplication(): Application {
    return store.getters.getOnlineApplication;
  }

  get currentSection(): string {
    return this.$route.query.sec as string;
  }
}
